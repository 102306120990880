import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout1 from './shared/layouts/Layout1'

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> })

// ---
// Default application layout
export const DefaultLayout = Layout1
// ---
// Document title template
export const titleTemplate = '%s - Bangna General Hospital'
// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/'
export const routes = [
  {
    path: '/',
    //component: lazy(() => import('./components/Home'))
    component: lazy(() => import('./components/vaccineSearch'))
  }, {
    path: '/page-2',
    component: lazy(() => import('./components/Page2'))
  }, {
    path: '/ReserveVaccine',
    component: lazy(() => import('./components/reservevaccine'))
  }, {
    path: '/ReserveVaccineUpload',
    component: lazy(() => import('./components/reservevaccineUpload'))
  }, {
    path: '/ReserveRTPCR',
    component: lazy(() => import('./components/reservertpcr'))
  }
  , {
    path: '/HomeRTPCR',
    component: lazy(() => import('./components/HomeRTPCR'))
  }
  , {
    path: '/ReserveRTPCRUpload',
    component: lazy(() => import('./components/ReserveVaccineReport'))
  }
  , {
    path: '/ReserveVaccineReport',
    component: lazy(() => import('./components/ReserveVaccineReport'))
  }
  , {
    path: '/ReserveVaccineReportAdmin',
    component: lazy(() => import('./components/ReserveVaccineReportAdmin'))
  }
  , {
    path: '/login',
    component: lazy(() => import('./components/Login'))
  }
  , {
    path: '/reportVaccine',
    component: lazy(() => import('./components/reportVaccine'))
  }
]
