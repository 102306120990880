import React from "react";

export const reportStoreContext = React.createContext();

const ReportStoreProvider = ({children}) => {
    const [profile, setProfile] = React.useState(null);
    const [mqttDeviceName, setMqttDeviceName] = React.useState(null);
    const [paramYear, setParamYear] = React.useState(null);
    const [paramMonth, setParamMonth] = React.useState(null);
    const [paramDay, setParamDay] = React.useState(null);

    const reportStore = {
        profile:profile,
        updateProfile:(profile) => setProfile(profile),

        mqttDeviceName:mqttDeviceName,
        updateMqttDeviceName:(mqttDeviceName) => setMqttDeviceName(mqttDeviceName),

        paramYear:paramYear,
        updateParamYear:(paramYear) => setParamYear(paramYear),

        paramMonth:paramMonth,
        updateParamMonth:(paramMonth) => setParamMonth(paramMonth),

        paramDay:paramDay,
        updateParamDay:(paramDay) => setParamDay(paramDay)
    }
    // const reportMqttDeviceName = {
    //     mqttDeviceName:mqttDeviceName,
    //     updateMqttDeviceName:(mqttDeviceName) => setMqttDeviceName(mqttDeviceName)
    // }

    return(
        <reportStoreContext.Provider value = {reportStore}>
            {children}
        </reportStoreContext.Provider>
    )
};

export default ReportStoreProvider;